import { Icon } from "@chakra-ui/react";
/*
* Instruction for adding an icon :
* 1. Clean your svg to make it very light
* 2. Remove all the svg Tag and insert the children inside the Icon tag
* 3. swith all the hypen with a capital ex: fill-rule -> fillRule
* 4. Change fill colors by "currentColor" ex: fill="#000" -> fill="CurrentColor" (only if you don't need a custom color)
*/
const LogoIcon = (props) => (
  <Icon viewBox="0 0 25 25" {...props} >
    <path fillRule="evenodd" clipRule="evenodd" d="M12.5 25.5C19.4036 25.5 25 19.9036 25 13C25 6.09644 19.4036 0.5 12.5 0.5C5.59644 0.5 0 6.09644 0 13C0 19.9036 5.59644 25.5 12.5 25.5ZM10.8912 20.273C10.9436 21.0827 11.041 22.585 9.15173 25.0463C10.2174 25.3419 11.3402 25.4998 12.5 25.4998C13.6962 25.4998 14.8532 25.3318 15.9486 25.018C14.0815 22.5733 14.1783 21.0794 14.2306 20.2727C14.234 20.2197 14.2372 20.1698 14.2397 20.1227C14.2721 19.5078 14.5768 19.4619 14.7252 19.5159C14.7252 19.7181 14.7737 20.155 14.9679 20.2845C17.3951 21.7812 20.1459 19.3136 20.5099 18.9091C19.8951 19.0385 19.5256 18.7473 19.4177 18.5854C18.641 17.5499 18.2581 14.5671 18.1637 13.2052C17.8077 11.3605 17.3913 10.2521 16.2624 8.634C15.6167 7.94799 14.5524 6.91581 12.5555 6.97497C10.5655 6.91981 9.50389 7.94952 8.85939 8.63431C7.73047 10.2524 7.31408 11.3608 6.95809 13.2055C6.8637 14.5674 6.48075 17.5502 5.70405 18.5858C5.59617 18.7476 5.2267 19.0388 4.61182 18.9094C4.97589 19.3139 7.7267 21.7815 10.1539 20.2848C10.3481 20.1553 10.3966 19.7184 10.3966 19.5162C10.5449 19.4622 10.8497 19.5081 10.882 20.123C10.8845 20.17 10.8878 20.2199 10.8912 20.2727L10.8912 20.273Z" fill="black"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.4491 16.7776C14.5747 16.8708 14.6009 17.0482 14.5076 17.1737C14.467 17.2284 14.4203 17.2949 14.3684 17.369C14.22 17.5806 14.0287 17.8533 13.815 18.0828C13.5242 18.3949 13.1265 18.7039 12.6217 18.7039C12.1311 18.7039 11.7173 18.4303 11.4008 18.1459C11.1686 17.9372 10.9555 17.6896 10.7891 17.4963C10.7306 17.4283 10.6777 17.3669 10.6319 17.3164C10.5269 17.2005 10.5357 17.0214 10.6516 16.9164C10.7675 16.8114 10.9466 16.8202 11.0516 16.9361C11.114 17.005 11.1774 17.0786 11.243 17.1548C11.4033 17.341 11.5764 17.5422 11.7795 17.7247C12.0631 17.9797 12.3437 18.1376 12.6217 18.1376C12.8855 18.1376 13.1418 17.9746 13.4005 17.6968C13.5854 17.4983 13.7384 17.2801 13.881 17.0767C13.9396 16.9932 13.9964 16.9121 14.053 16.836C14.1462 16.7105 14.3236 16.6843 14.4491 16.7776Z" fill="black"/>
  </Icon>
)
export default LogoIcon;