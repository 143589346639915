export const chakraSwitch = {
  baseStyle: {
    thumb: {
      bg: 'white',
    },
    track: {
      bg: 'background.component.grey',
      _checked: {
        bg: 'fill.grey',
      },
    },
  },
  variants: {
    double: {
      track: {
        bg: 'fill.grey',
        _checked: {
          bg: 'fill.grey',
        },
      }
    },
    coral: {
      track: {
        bg: 'background.component.grey',
        _checked: {
          bg: 'fill.coral',
        },
      },
    },
    orangeDouble: {
      track: {
        bg: 'fill.coral',
        _checked: {
          bg: 'fill.coral',
        },
      },
    }
  },
  defaultProps: {
    variant: 'orange',
  }
};