import { Flex } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";


export default function Personas() {

  const [imageList, setImageList] = useState();
  const sliderRef = useRef();

  useEffect(() => {
    if(!imageList) {
      const newImageList = [];
      for (let i = 1; i < 14 ; i++) {
        newImageList.push(
          <img 
            key={i}
            src={`/img/home/persona${i}.webp`}
            width={"100px"}
            height={"100px"}
          />
       );
      }
      setImageList(newImageList)
    }
  }, [imageList])

  return (
    <Flex
      overflow={"hidden"}
      borderRadius={"20px"}
      backgroundColor={"background.coral"}
      border={"1px solid"}
      borderColor={"divider.coral"}
      padding={"20px"}
      >
      <Flex
        gap={"20px"}
        ref={sliderRef}
        className="animated-slide"
        onAnimationEnd={()=> {
          const firstChild = sliderRef.current.firstElementChild;
          sliderRef.current.removeChild(firstChild);
          sliderRef.current.appendChild(firstChild);
          sliderRef.current.classList.remove('animated-slide');
          setTimeout(() => {
            sliderRef.current.classList.add('animated-slide');
          })
        }}
        >
        {imageList}
      </Flex>
    </Flex>
  );
}


// <img src="/img/home/illustration.webp" />

