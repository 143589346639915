export const heading = {
  baseStyle: {

  },
  variants: {
    primary: {
      fontFamily: 'Roboto, arial, sans-serif',
      fontSize: '21px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '18px',
      color: 'text.primaryGrey'
    },
    secondary: {
      fontFamily: 'Roboto, arial, sans-serif',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '18px',
      color: 'text.primaryBlue'
    },
    tertiary: {
      fontFamily: 'Roboto, arial, sans-serif',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '21px',
      color: 'text.primaryGrey'
    },
  },
  defaultProps: {
    variant: 'primary',
  }
};