import { Box, Flex, Text } from "@chakra-ui/react";
import Personas from "./personas";
import { useGSAP } from '@gsap/react';
import gsap from 'gsap'
import { useRef } from "react";

export default function Many() {

  const containerRef = useRef();

  useGSAP(
    () => {
      gsap.from(".fade-in", {
        y: 50,
        opacity: '0',
        duration: 0.5,
        ease: "power2.inOut",
        stagger: 0.2,
        scrollTrigger: {
          trigger: ".fade-in",
        }
      });
    },
    { scope: containerRef }
); 

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      ref={containerRef}
    >
      <Text
        fontSize={"30px"}
        fontWeight={"500"}
        maxWidth={"390px"}
        textAlign={"center"}
        textwrap={"nowrap"}
        py={"50px"}
      >
          Do you work on many <br/>
        <Text as="span" color={"fill.coral"}>
          personas 
        </Text>
          and 
        <Text as="span" color={"fill.coral"}>
          products?
        </Text>
      </Text>
      <Personas />
      <Flex
        width={"100%"}
        gap={3}
        justifyContent={"center"}
        px={"20px"}
        py={"50px"}
        flexDirection={["column", 'row']}
      >
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          w={"100%"}
          maxW={"304px"}
          justifyContent={"center"}
          textAlign={"center"}
          className="fade-in"
        >
          <Text
            color={"fill.coral"}
            fontSize={"25px"}
            fontWeight={"600"}
          >1</Text>
          <Text
            fontSize={"16px"}
            fontWeight={"500"}
            mt={2}
          >Better keep track of insights</Text>
          <Text
            fontSize={"14px"}
            color={"text.medium.grey"}
            mt={"5px"}
          >so you won’t forget to address <br />any user’s frustration anymore</Text>
        </Flex>
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          w={"100%"}
          maxW={"304px"}
          justifyContent={"center"}
          textAlign={"center"}
          className="fade-in"
        >
          <Text
            color={"fill.coral"}
            fontSize={"25px"}
            fontWeight={"600"}
          >2</Text>
          <Text
            fontSize={"16px"}
            fontWeight={"500"}
            mt={2}
          >Centralize your work</Text>
          <Text
            fontSize={"14px"}
            color={"text.medium.grey"}
            mt={"5px"}
          >so you don't spend time going <br /> through many old studies anymore</Text>
        </Flex>
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          w={"100%"}
          maxW={"304px"}
          justifyContent={"center"}
          textAlign={"center"}
          className="fade-in"
        >
          <Text
            color={"fill.coral"}
            fontSize={"25px"}
            fontWeight={"600"}
          >3</Text>
          <Text
            fontSize={"16px"}
            fontWeight={"500"}
            mt={2}
          >Share  your work more easily</Text>
          <Text
            fontSize={"14px"}
            color={"text.medium.grey"}
            mt={"5px"}
          >So you can have more efficient teamwork, better handovers, and smoother onboarding.</Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

