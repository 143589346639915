import {Flex} from "@chakra-ui/react";
import Features from '../home/components/features';

export default function FeaturesPage() {

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      p={"20px"}
      gap={"50px"}
    >
      <Features/>
      <Flex maxW={"100%"} w={"1080px"} h={"720px"}>
        <iframe width="100%" height={"100%"} src="https://www.youtube.com/embed/ukM0ZY4bHfY?si=FeHaD4RTs_56xsDt" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      </Flex>
    </Flex>
  );
}
