import { Button, Flex, Text } from "@chakra-ui/react";
import Illustration from "./illustration";
import { Link, useNavigate } from "react-router-dom";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import gsap from 'gsap'

export default function Baseline() {

  const container = useRef();

  useGSAP(
    () => {
      gsap.from(".slide-in-1, .slide-in-2, .slide-in-3", {
        x: -200,
        opacity: '0',
        duration: 0.5,
        ease: "power2.inOut",
        stagger: 0.1
      });
    },
    { scope: container }
  );

  return (
    <Flex
      backgroundColor={"background.coral"}
      padding={"30px"}
      borderRadius={"20px"}
      minHeight={"calc(100vh - 200px)"}
      height={"fit-content"}
      gap={["30px", "70px"]}
      width={"100%"}
      alignItems={["center"]}
      justifyContent={"center"}
      border="1px solid"
      borderColor={"divider.coral"}
      ref={container}
      flexDirection={["column","row"]}
    >
      <Flex
        flexDirection={"column"}
        flexShrink={"0"}
      >
        <Text
          maxW={"513px"}
          fontSize={["30px", "30px", "40px"]}
          fontWeight={"600"}
          lineHeight={["40px", "40px", "55px"]}
          className="slide-in-1"
        >Better organize<br /> and share your insights</Text>
        <Text
          color={"text.medium.grey"}
          maxW={"300px"}
          fontSize={"20px"}
          lineHeight={"30px"}
          className="slide-in-2"
          mt={1}
        >by creating your own Persona and Product Library.</Text>
        <Link
          to={process.env.REACT_APP_BIBLIONA_URL}
          className="slide-in-3"
          target='_blank'>
          <Button
            variant={"coralFill"}
            width={"100px"}
            mt={3}
          >Try it!</Button>
        </Link>
      </Flex>
      <Illustration />
    </Flex>
  );
}

