import {Flex, Text } from "@chakra-ui/react";
import { useState } from "react";

export default function Features() {
  
  const [slideList, setSlideList] = useState([{
    img: 'feature-home',
    title: 'Make your own persona and product library!'
  }, {
    img: 'feature-insights',
    title: 'List all your insights!'
  }, {
    img: 'feature-archive',
    title: 'Archive outdated insights!'
  }, {
    img: 'feature-share',
    title: 'Share and collaborate with colleagues!'
  }])
  const [currentSlide, setCurrentSlide] = useState(slideList[0])

  return (
    <>
    <Flex
      display={["none","inherit"]}
      backgroundColor={"background.grey"}
      px={"50px"}
      py={["30px","50px"]}
      borderRadius={"20px"}
      gap={"40px"}
      width={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      border="1px solid"
      borderColor={"divider.grey"}
      flexDirection={"column"}
      overflow={"hidden"}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Text
          fontSize={["25px", "30px"]}
          fontWeight={"500"}
          marginBottom={"30px"}
          alignItems={"center"}
          textAlign={"center"}
        >{currentSlide.title}
        </Text>
        <Flex
          maxW={"800px"}
          width={"100%"}
          transform={`translateX(-${(slideList.indexOf(currentSlide)) * 100}%)`}
          transition={"transform 0.3s ease-in-out"}
        >
          {slideList.map((slide, i) => (
            <Flex
              style={{
                perspective: "1000px"
              }}
              key={i}
              maxW={"800px"}
              width={"100%"}
              flexShrink={"0"}
              px={["2vw", "5vw"]}
            >
              <Flex
                maxW={"800px"}
                width={"100%"}
                borderRadius={"20px"}
                overflow={"hidden"}
                boxShadow={"0 4px 24px rgba(0,0,0,0.15)"}
                transform={`rotateY(${(i - slideList.indexOf(currentSlide)) * 20}deg) scaleY(${(1 - (Math.abs(i - slideList.indexOf(currentSlide)) * 0.2))})`}
                onClick={() => { setCurrentSlide(slide) }}
                transition={"transform 0.3s ease-in-out"}
                cursor={"pointer"}
              >
                <img src={`/img/home/${slide.img}.webp`} width={"100%"} />
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex gap={1}>
        {slideList.map((slide, i) => (
          <Flex
            key={i}
            w={"10px"}
            height={"10px"}
            backgroundColor={slideList.indexOf(currentSlide) === i ? "fill.coral" : 'background.coral'}
            border={"1px solid"}
            borderColor={"divider.coral"}
            cursor={'pointer'}
            borderRadius={"100%"}
            onClick={() => {
              setCurrentSlide(slide)
            }} />
        ))}
      </Flex>
    </Flex>
    <Flex
      flexDirection={"column"}
      borderTop={"1px solid"}
      borderColor={"divider.grey"}
      display={["inherit","none"]}
    >
      <Text
      py={3}
      fontSize={"25px"}
      fontWeight={"500"}
      >Features</Text>
    <Flex
      flexDirection={"column"}
      gap={"20px"}
    >
         {slideList.map((slide, i) => (
          <Flex
            flexDirection={"column"}
            border="1px solid"
            borderColor={"divider.coral"}
            backgroundColor={"background.coral"}
            borderRadius={"10px"}
            overflow={"hidden"}
            key={i}
          >
            <Text
              px={3}
              py={2}
              fontWeight={"500"}
              fontSize={"18px"}
            >{slide.title}</Text>
            <Flex
              borderTop="1px solid"
              borderColor={"divider.coral"}
            >
              <img src={`/img/home/${slide.img}-mobile.webp`} width={"100%"} />
            </Flex>
          </Flex>
        ))}
    </Flex>
    </Flex>
    </>
  );
}