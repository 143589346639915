import { ChakraProvider } from '@chakra-ui/react';
import './App.css';
import AppRoutes from './Routes';
import theme from './lib/theme/chakra.theme';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function App() {
  return (
    <>
      <ChakraProvider theme={theme}>
        <AppRoutes />
      </ChakraProvider>
    </>
  );
}

export default App;
