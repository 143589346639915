'use client'

import React, { useEffect, useState } from 'react';
import {
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react'
import LogoIcon from './icons/logoIcon';

export default function Logo(props) {

  const { size = 25 } = props;
  const [fontSize, setFontSize] = useState('22px');

  useEffect(() => {
    const newFontSize = (size / 1.13) + 'px'
    setFontSize(newFontSize)
  }, [size])

  return (
    <>
      <Flex
        gap={2}
        alignItems={'center'}
        >
        <Icon
          as={LogoIcon}
          boxSize={(size + 'px')}
        />
        <Text fontFamily={'var(--font-vidaloka)'} fontSize={fontSize}>Bibliona</Text>
      </Flex>
    </>
  );
}
