export const modal = {
  baseStyle: {
    dialog: {
      borderRadius: '4px',
      maxHeight: "calc(100vh - 50px)"
    },
    body: {
      bg: 'background.grey',
      minHeight: '100px',
      paddingInlineStart: 'inherit',
      paddingInlineEnd: 'inherit',
      paddingTop: 'inherit',
      paddingBottom: 'inherit',
      overflowY: "auto",
    },
    dialogContainer: {
      alignItems: 'center'
    },
    header: {
      borderBottom : '1px solid',
      borderColor: 'divider.grey',
      fontSize: '20px',
      color: 'text.primaryBlue',
      px: '3',
      py: '3'
    },
    footer: {
      borderTop: '1px solid',
      borderColor: 'divider.grey',
      px: '3',
      py: '3'
    }
  },
  variants: {

  },
  defaultProps: {
  }
};