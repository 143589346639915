export const text = {
  subtitle: {
    fontFamily: 'Roboto, arial, sans-serif',
    fontSize: '12px',
    color: 'text.secondaryGrey'
  },
  body: {
    fontSize: '14px',
    fontFamily: 'Roboto, arial, sans-serif',
    color: 'text.primaryGrey'
  },
  title : {
    fontSize: '18px',
    fontFamily: 'var(--font-vidaloka)',
    color: 'text.primary'
  }
};