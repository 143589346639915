import { Button, Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import * as ct from 'countries-and-timezones';

export default function PricingPage() {

  const [timezone, setTimezone] = useState();
  const [usesEuro, setUsesEuro] = useState(false);

  const locateUser = () => {
    const euroCountries = ['AT', 'BE', 'CY', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SI', 'ES'];
    const newTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const countries = ct.getCountriesForTimezone(newTimezone);
    let usesEuro = false;
    countries.forEach((country) => {
      if (euroCountries.indexOf(country.id) !== -1) {
        usesEuro = true;
      }
    });
    setUsesEuro(usesEuro)
    setTimezone(newTimezone);
  }

  useEffect(() => {
    if (!timezone) {
      locateUser();
    }
  }, [timezone])

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      p={"20px"}
      gap={"50px"}
    >
      <Text
        fontSize={"40px"}
        color={"text.dark.grey"}
      >Pricing</Text>
      <Flex
        gap={3}
        flexWrap={"wrap"}
        flexDirection={["column", "row"]}
      >
        <Flex
          flexDirection={"column"}
          border={"1px solid"}
          borderColor={"divider.coral"}
          borderRadius={"20px"}
          backgroundColor={"background.coral"}
          padding={"20px"}
          flex={"1"}
        >
          <Text color="text.medium.coral">Basic</Text>
          <Text
            color="text.medium.coral"
            fontSize={"30px"}
            lineHeight={"30px"}
            fontFamily={'var(--font-vidaloka)'}>Free</Text>
          <Link
            to={process.env.REACT_APP_BIBLIONA_URL + '/auth/sign-in'}
            target="_blank"
            >
            <Button
              my={"30px"}
              variant="coralFill"
              width={"100%"}
            >Try it!</Button>
          </Link>
          <Flex flexDirection={"column"}>
            <Text flexDirection={"column"} color={"text.medium.coral"}>Includes: </Text>
            <UnorderedList color={"text.medium.coral"}>
              <ListItem>Unlimited Products</ListItem>
              <ListItem>Unlimited Personas</ListItem>
              <ListItem>Unlimited Experiences</ListItem>
            </UnorderedList>
          </Flex>
        </Flex>
        <Flex
          flexDirection={"column"}
          border={"1px solid"}
          borderColor={"divider.blue"}
          borderRadius={"20px"}
          backgroundColor={"background.blue"}
          padding={"20px"}
          flex={"1"}
        >
          <Text color="text.medium.blue">Pro</Text>
          <Flex alignItems={"baseline"}>
            <Text
              color="text.medium.blue"
              fontSize={"30px"}
              fontFamily={'var(--font-vidaloka)'}
              lineHeight={"30px"}
            >$9.99</Text>
            <Text
              color="text.medium.blue"
              fontSize={"20px"}
              fontFamily={'var(--font-vidaloka)'}
              lineHeight={"30px"}
            >/month</Text>
          </Flex>
          <Link 
          to={process.env.REACT_APP_BIBLIONA_URL + '/auth/sign-in?redirect_url=/profile&arg=modal=upgrade-plan'}
          target="_blank"
          >
            <Button
              my={"30px"}
              variant="blueFill"
              width={"100%"}
            >Select</Button>
          </Link>
          <Flex flexDirection={"column"}>
            <Text flexDirection={"column"} color={"text.medium.blue"} >Includes: </Text>
            <UnorderedList color={"text.medium.blue"}>
              <ListItem>Sharing and collaboration features</ListItem>
              <ListItem>Unlimited Products</ListItem>
              <ListItem>Unlimited Personas</ListItem>
              <ListItem>Unlimited Experiences</ListItem>
            </UnorderedList>
          </Flex>
        </Flex>
        <Flex
          flexDirection={"column"}
          border={"1px solid"}
          borderColor={"divider.purple"}
          borderRadius={"20px"}
          backgroundColor={"background.purple"}
          padding={"20px"}
          flex={1}
        >
          <Text color="text.medium.purple">Enterprise</Text>
          <Flex alignItems={"baseline"}>
              <Text
                color="text.medium.purple"
                fontSize={"30px"}
                fontFamily={'var(--font-vidaloka)'}
                lineHeight={"30px"}
              >$19.99</Text>
              <Text
                color="text.medium.purple"
                fontSize={"20px"}
                fontFamily={'var(--font-vidaloka)'}
                lineHeight={"30px"}
              >/user/month</Text>

          </Flex>
          <Link
            to={process.env.REACT_APP_BIBLIONA_URL + '/auth/sign-in?redirect_url=/profile&arg=modal=upgrade-plan'}
            target="_blank"
          >
              <Button
                my={"30px"}
                variant="purpleFill"
                width={"100%"}
              >Select</Button>
          </Link>
          <Flex flexDirection={"column"}>
            <Text flexDirection={"column"} color={"text.medium.purple"} >Includes: </Text>
            <UnorderedList color={"text.medium.purple"}>
              <ListItem>Library of Personas and Products accessible to any collaborators</ListItem>
              <ListItem>Sharing and collaboration features</ListItem>
              <ListItem>Common Billing</ListItem>
              <ListItem>Unlimited Products</ListItem>
              <ListItem>Unlimited Personas</ListItem>
              <ListItem>Unlimited Experiences</ListItem>
            </UnorderedList>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection={"column"} alignItems={"center"}>
        <Link to="/features">
          <Button width={"143px"} variant="greyFill" size={"md"} mb={2}>See features</Button>
        </Link>
        <Link to="https://www.support.bibliona.com/" target='_blank'>
          <Button width={"143px"} variant="greyOutline" size={"md"} mb={2}>Contact Us</Button>
        </Link>
      </Flex>
    </Flex>
  );
}
