import { MdOutlineThumbUp, MdOutlineThumbDown, MdOutlineTouchApp, MdSettings, MdThumbDown, MdFormatQuote } from 'react-icons/md';
import { Button, Divider, Flex, Icon, Image, List, ListIcon, ListItem, Text } from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import Many from './components/many';
import Baseline from './components/baseline';
import Features from './components/features';

export default function HomePage() {
  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      p={"20px"}
      borderRadius={"20px"}
      paddingTop={"0"}
    >
      <Baseline />
      <Many />
      <Features />
      <Flex
        fontSize={"35px"}
        alignItems={"center"}
        flexDirection={"column"}
        py={"50px"}
        px={"20px"}
        pb={"100px"}
        fontWeight={"500"}
        gap={"20px"}
      >
        <Flex
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Text>Try it!</Text>
          <Text textAlign={"center"}>
            <Text as={"span"}>The basic plan is </Text>
            <Text as={"span"} color={"fill.coral"}>free</Text>
            <Text as={"span"}>!</Text>
          </Text>
        </Flex>
        <Flex
          flexDirection={"column"}
        >
          <Link to={process.env.REACT_APP_BIBLIONA_URL} target='_blank'>
            <Button variant={"coralFill"}>Start for Free</Button>
          </Link>
          <Link to="/pricing" >
            <Button variant={"coralTransparent"}>See pricing</Button>
          </Link>
        </Flex>
      </Flex>
      <Divider />
      <Flex
          p={3}
          maxW={"1080px"}
          w={"100%"}
          h={"720px"}
         >
         <iframe width="100%" height={"100%"} src="https://www.youtube.com/embed/ukM0ZY4bHfY?si=FeHaD4RTs_56xsDt" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </Flex>
    </Flex>
  );
}
