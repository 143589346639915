export const draggableBox = {
  variants: {
    base: {
      border:"2px dashed",
      borderColor:"divider.grey",
      bg:"background.grey",
      borderRadius: "2px",
      transition: 'border-color 300ms'
    },
    dragging: {
      border:"2px dashed",
      borderColor:"text.light.grey",
      bg:"background.grey",
      borderRadius: "2px"
    },
  },
};