'use client'

import React, { useContext, useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/layout';
import Logo from './logo';
import { Button, Tag } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import HeaderMenuMobile from './headerMenuMobile';
import HeaderMenuDesktop from './headerMenuDesktop';
export default function Header() {

  const location = useLocation();

  return (
    <>
      <Flex
        borderColor="divider.grey"
        px={3}
        py={2}
        height={"62px"}
        backgroundColor="white"
        alignItems={'center'}
        justifyContent={"space-between"}
        flex={"0 0 auto"}
        position={"sticky"}
        zIndex={"100"}
        top="0"
      >
        <Flex
          flexDirection={"row"}
          alignItems={"center"}
        >
          <Link to="/home" >
            <Logo />
          </Link>
        </Flex>
        <Flex display={['none', 'inherit']}>
          <HeaderMenuDesktop />
        </Flex>
        <Flex display={['inherit', 'none']}>
          <HeaderMenuMobile />
        </Flex>
      </Flex>
    </>
  );
}
