import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './home/HomePage';
import RootLayout from './RootLayout';
import NotFoundPage from './not-found/NotFoundPage';
import FeaturesPage from './features/FeaturesPages';
import PricingPage from './pricing/PricingPage';

function AppRoutes({children}) {
  return (
    <Router>
      <RootLayout>
        <Routes>
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/features" element={<FeaturesPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
        </RootLayout>
    </Router>
  );
}

export default AppRoutes;