import React from 'react';
import Header from './components/header';
import {Flex } from '@chakra-ui/react';

export default function RootLayout({ children }) {

  return (
    <>
      <Header />
      <Flex
        backgroundColor={"white"}
        flexDirection="column"
        width="100%"
        height="100%"
      >
        {children}
      </Flex>
    </>
  )
}
