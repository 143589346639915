export const popover = {
  baseStyle: {
    content: {
      border: 'none',
      outline: 'none',
      boxShadow: '0px 0px 6px 0px rgba(163,163,163,0.15)'
    },
    body: {
      p: '0px'
    },
    popper: {
      border: 'none',
      outline: 'none'
    }
  }
};