
import { Flex } from '@chakra-ui/react';
import { useGSAP } from '@gsap/react';
import { useEffect, useRef } from 'react';
import gsap from 'gsap'

export default function Illustration() {

  const containerRef = useRef();

  useGSAP(
    () => {
      gsap.from(".illustration", {
        scale : 0.8,
        opacity: '0',
        duration: 0.35,
        ease: "power2.inOut",
      });
    },
    { scope: containerRef }
); 

  const updateSize = () => {
    if(containerRef.current) {
      const height =  containerRef.current.offsetWidth * 0.74;
      containerRef.current.height = height;
    }
  }

  const updatePerspective = (event) => {
    const ratio = event.clientX / window.innerWidth;
    const rotate = (ratio * 30) - 15;
    if(containerRef.current) {
      containerRef.current.style.transform = `rotateY(${rotate}deg)`;
    }
  }
  
  useEffect(() => {
    if(containerRef.current) {
      updateSize();
    }
  }, [containerRef])
  

  useEffect(() => {
    const listener = window.addEventListener('resize', () => {
      updateSize();
    });
    const listener2 = window.addEventListener('mousemove', (event) => {
      updatePerspective(event)
    });
    return () => {
    removeEventListener( 'resize', listener);
    removeEventListener( 'mousemove', listener2);
    }
  }, [])

  useEffect(() => {
      
  }, [])


  return (
    <Flex 
      width={["100%", "100px"]}
      flex={"1"}
      maxW={["490px"]}
      maxHeight={"365px"}
      style={{
      perspective: '1000px'
    }}>
      <Flex
        backgroundColor={'background.coral'}
        ref={containerRef}
        width={"100%"}
        maxW={"490px"}
        maxHeight={"365px"}
        >
          <img
            width={"100%"}
            height={"100%"}
            className='illustration'
            src="/img/home/illustration.webp"
          />
      </Flex>
    </Flex>
  );
}



