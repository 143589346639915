'use client'

import React, { useContext, useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/layout';
import Logo from './logo';
import { Button, Tag } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
export default function HeaderMenuDesktop() {

  const location = useLocation();

  return (
    <Flex
      flexDirection={"row"}
      alignItems={"center"}
    >
      <Link to="/">
        <Text w="75px" textAlign={"center"} color={(
          location.pathname.includes('home') ? 'fill.coral' : 'text.medium.dark'
        )}>Home</Text>
      </Link>
      <Link to="/features">
        <Text w="75px" textAlign={"center"} color={(
          location.pathname.includes('features') ? 'fill.coral' : 'text.medium.dark'
        )}>Features</Text>
      </Link>
      <Link to="/pricing">
        <Text w="75px" textAlign={"center"} color={(
          location.pathname.includes('pricing') ? 'fill.coral' : 'text.medium.dark'
        )}>Pricing</Text>
      </Link>
      <Link to="https://www.support.bibliona.com/" target='_blank'>
        <Text w="75px" textAlign={"center"} color={(
          location.pathname.includes('support') ? 'fill.coral' : 'text.medium.dark'
        )}>Support</Text>
      </Link>
      <Link to={process.env.REACT_APP_BIBLIONA_URL} >
        <Button variant="coralFill" ml={3}>Try it for Free</Button>
      </Link>
    </Flex>
  );
}
