export const textarea = {
  variants: {
    regular: {
        bg: "white",
        border: "1px solid",
        borderColor: "divider.grey",
        focusBorder: '1px solid',
        color: 'text.medium.grey',
        px: 3,
        height: '42px',
        _placeholder: {
          color: 'text.light.grey'
        },
        _focus: {
          bg: 'background.white',
          color: 'text.dark.grey',
          boxShadow: '0px 0px 6px 0px rgba(163,163,163,0.15)'
        },
    },
  },
  defaultProps: {
    variant: "regular",
  },
};